import { getLocaleUrl, getCurrencyCode } from 'client-utils/utilities-page';
import { getActiveAbTests } from 'client-utils/utilities-abtest';
import { getValidGender } from 'client-utils/utilities-gender';
import { safelyParse } from 'universal/utilities-cookies';
import { updateMiniCartItemCount } from 'storefront/components/Header/MiniCart/miniCart-actions';
import { UNITED_STATES } from 'storefront/components/constants';
import { GENDER_PLACEMENT } from 'universal/constants/genderPlacement';
import find from 'lodash/find';
import { countryCurrencyData } from 'storefront/components/CountrySelector/CountryChooser/countryCurrencyData';
import {
  APP_SET_USER,
  APP_SET_SESSION,
  APP_LOSE_FOCUS,
  APP_PUT_IN_FOCUS,
  APP_SET_LOCALE,
  APP_REQUEST_RECEIVED,
  APP_SET_REVISIT_USER,
  APP_PLA_COOKIE_RECEIVED,
  APP_COOKIES_RECEIVED,
  APP_PARSE_PERSONALIZE,
  APP_AB_TEST_SESSION_DATA_RECEIVED,
  APP_SET_HEADER_STATE,
  SET_GENDER,
  SET_TYPEAHEAD_UTAG,
} from './app-action-types';

export function loseFocus(currentComponentInFocus) {
  return (dispatch) => dispatch({ type: APP_LOSE_FOCUS, currentComponentInFocus });
}

export function putComponentInFocus(componentInFocus) {
  return (dispatch) => dispatch({ type: APP_PUT_IN_FOCUS, componentInFocus });
}

export const setUserAction = (user) => {
  return { type: APP_SET_USER, user };
};

const getSessionFromRequest = (request) => {
  const whitelist = [
    'JSESSIONID',
    'DYN_USER_ID',
    'DYN_USER_CONFIRM',
    'W2A',
    'WID',
    'TLTSID',
    'dt_personalize_data',
    'dt_personalize_preview',
    '_cplid',
    '_optuid',
    'AGA',
    'at_preview_token',
    'at_preview_index',
    'at_preview_listed_activities_only',
    'at_preview_evaluate_as_true_audience_ids',
    'SPCR',
    'defaultToggles',
    'force-opty',
    'utm_source',
    'dt_gender',
    'dt_gender_placement',
  ];
  const { 'user-agent': userAgent = '', host, referer } = request.headers;
  const url = `https://${host}${request.originalUrl}`;

  return whitelist.reduce((session, key) => {
    if (key === 'dt_gender' && request.cookies[key]) {
      session[key] = getValidGender(request.cookies[key]);
    } else {
      session[key] = request.cookies[key];
    }
    return session;
  }, { url, referer, userAgent });
};

const getUserData = (
  tmsData,
  profileData,
  countryCodeByAkamai = '',
  ucaProfileDataCookie,
  toggles,
  userEmail
) => ({
  ucid:
    ucaProfileDataCookie.universal_customer_id || tmsData.universal_customer_id,
  rid: ucaProfileDataCookie.countryPreference || tmsData.country_code,
  email: userEmail || tmsData.customer_email,
  name: toggles?.DISABLE_ATG_LOGIN
    ? ucaProfileDataCookie.firstName
    : profileData.firstName,
  securityStatus: toggles?.DISABLE_ATG_LOGIN
    ? ucaProfileDataCookie.securityStatus
    : profileData.securityStatus,
  countryCodeByAkamai,
});

const getLocaleFromProfileData = (localeData) => ({
  countryCode: localeData.countryPreference,
  currencyCode: localeData.currencyPreference,
  localeUrl: getLocaleUrl(localeData.countryPreference),
});

const dispatchUserProfile = (
  request,
  tmsData,
  profileData,
  dispatch,
  countryCodeByAkamai = '',
  ucaProfileDataCookie,
  toggles,
  userEmail
) => {
  dispatch(setUserAction(getUserData(
    tmsData,
    profileData,
    countryCodeByAkamai,
    ucaProfileDataCookie,
    toggles,
    userEmail
  )));
  dispatch({ type: APP_SET_SESSION, session: getSessionFromRequest(request) });
  dispatch({
    type: APP_SET_LOCALE,
    locale: getLocaleFromProfileData(profileData),
  });
  if (profileData.cartItemCount >= 0) {
    updateMiniCartItemCount(profileData.cartItemCount)(dispatch);
  }
};

const replaceLocalePath = (url, localePath) => {
  let replacedUrl = /^\/en-[a-zA-Z]{2}$/.test(url) ? `${url}/` : url;
  replacedUrl = replacedUrl.replace(/^\/en-.*?\//, '/');
  return localePath === '/en-us' ? replacedUrl : localePath + replacedUrl;
};

const getCountryCodeByAkamai = (headers) => {
  const regex = /country_code=([A-Z]{2})/;
  const { 'akamai-edgescape': akamaiEdgescape } = headers;
  const akamaiEdgescapeRegex = regex.exec(akamaiEdgescape);
  return akamaiEdgescapeRegex && akamaiEdgescapeRegex.length > 1 ? akamaiEdgescapeRegex[1] : '';
};

const getCountryCode = (profileData, headers) => {
  if (!profileData.countryPreference) {
    const regex = /country_code=([A-Z]{2})/;
    const { 'akamai-edgescape': akamaiEdgescape } = headers;
    const akamaiEdgescapeRegex = regex.exec(akamaiEdgescape);
    return akamaiEdgescapeRegex && akamaiEdgescapeRegex.length > 1 ? akamaiEdgescapeRegex[1] : '';
  }
  return profileData.countryPreference;
};

const urlContainsLocalePath = (url, localePath) => {
  if (localePath !== '/en-us') {
    return url && url.startsWith(`${localePath}/`);
  }
  return url && !url.startsWith('/en-');
};

const dispatchRevisitUser = (revisitUserCookie, dispatch) => {
  if (revisitUserCookie) {
    dispatch({
      type: APP_SET_REVISIT_USER,
      revisitUser: revisitUserCookie,
    });
  }
};

export const setRevisitUser = (revisitUserValue, toggles) => (dispatch) => (
  dispatchRevisitUser(revisitUserValue, dispatch, toggles)
);

const forceDomesticExperience = (profileData, request, internationalEnabled) => (
  !internationalEnabled || process.env.BRAND_NAME === 'HC'
   || (profileData && (request.cookies.utm_source === 'google_shopping')));

export const requestReceived = (
  request,
  response,
  tmsData,
  toggles,
  internalIps,
  abTestIds = {}
) => (dispatch) => {
  const ucaProfileDataCookie = safelyParse(request.cookies.ucaProfileData) || {};
  const profileData = safelyParse(request.cookies.profile_data) || {};
  const getEmailFromCognitoCookie = () => {
    if (typeof request.cookies !== 'undefined') {
      return Object.keys(request.cookies).filter(
        (item) => item.startsWith('CognitoIdentityServiceProvider') && item.endsWith('LastAuthUser')
      )[0];
    }
    return null;
  };
  const userEmail = request.cookies[getEmailFromCognitoCookie()] || '';
  if (forceDomesticExperience(profileData, request, toggles?.INTERNATIONAL)) {
    profileData.countryPreference = 'US';
    profileData.currencyPreference = 'USD';
    ucaProfileDataCookie.countryPreference = 'US';
    ucaProfileDataCookie.currencyPreference = 'USD';
  }
  const countryCode = getCountryCode(profileData, request.headers) || UNITED_STATES;
  const localeUrl = getLocaleUrl(countryCode);
  const countryCodeByAkamai = getCountryCodeByAkamai(request.headers) || UNITED_STATES;


  if (
    toggles?.INTERNATIONAL
    && process.env.BRAND_NAME !== 'HC'
    // eslint-disable-next-line camelcase
    && !(request.query?.utm_source === 'google_shopping')
    && !urlContainsLocalePath(request.url, localeUrl)
    && !request?.headers?.referer?.indexOf('www.google.com') >= 0
  ) {
    response.redirect(
      302,
      replaceLocalePath(request.url, getLocaleUrl(countryCode))
    );
    return;
  }
  dispatchRevisitUser(request.cookies.revisitUser, dispatch, toggles);

  if (process.env.BRAND_NAME !== 'HC') {
    profileData.countryPreference = countryCode;
    profileData.currencyPreference = profileData.currencyPreference
        || getCurrencyCode(countryCode);
    ucaProfileDataCookie.countryPreference = countryCode;
    ucaProfileDataCookie.currencyPreference = ucaProfileDataCookie.currencyPreference
            || getCurrencyCode(countryCode);
  }


  dispatchUserProfile(
    request,
    tmsData,
    profileData,
    dispatch,
    countryCodeByAkamai,
    ucaProfileDataCookie,
    toggles,
    userEmail
  );
  dispatch({ type: APP_PLA_COOKIE_RECEIVED, payload: { ipla: request.headers.ipla } });
  let analyticsProfileData = {};
  if (toggles?.DISABLE_ATG_LOGIN) {
    const getGuestId = () => {
      if (typeof request.cookies !== 'undefined') {
        return Object.keys(request.cookies).filter(
          (item) => item.startsWith('guestUser') && item.endsWith('Sub')
        )[0];
      }
      return null;
    };
    const guestId = request.cookies[getGuestId()] || '';
    const getServerDateTime = () => new Date().getTime().toString();

    const getCountryName = (countryCode) => {
      const defaultCountry = {
        countryName: 'United States',
      };
      const country = find(
        countryCurrencyData,
        (country) => country.countryCode === countryCode
      ) || defaultCountry;
      return country.countryName;
    };
    analyticsProfileData = {
      account_registration: 'false',
      country_code: ucaProfileDataCookie?.countryPreference || 'US',
      customer_country: getCountryName(countryCodeByAkamai) || 'United States',
      order_currency_code: ucaProfileDataCookie?.currencyPreference || 'USD',
      // eslint-disable-next-line camelcase
      profile_type: ucaProfileDataCookie?.profile_type || 'customer',
      customer_email: userEmail,
      // eslint-disable-next-line camelcase
      customer_registered: ucaProfileDataCookie?.customer_registered || userEmail ? 'true' : 'false',
      customer_status: userEmail ? 'Logged In' : 'Not recognized',
      // eslint-disable-next-line camelcase
      logged_in_status: ucaProfileDataCookie?.logged_in_status || userEmail ? 'true' : 'false',
      server_date_time: getServerDateTime(),
      universal_customer_id: ucaProfileDataCookie?.universal_customer_id || '',
      web_id: ucaProfileDataCookie?.universal_customer_id || guestId || '',
    };
  }
  dispatch({
    type: APP_REQUEST_RECEIVED,
    payload: {
      request, tmsData, profileData, ucaProfileDataCookie, analyticsProfileData,
    },
  });
  dispatch({
    type: APP_COOKIES_RECEIVED,
    cookies: request.cookies,
    abTestIds: getActiveAbTests(abTestIds),
  });
  dispatch({ type: APP_PARSE_PERSONALIZE, payload: { request, internalIps } });
  dispatch({ type: APP_AB_TEST_SESSION_DATA_RECEIVED, mboxCookie: request.cookies.mbox });
};

export const setHeaderState = (headerState) => {
  return (dispatch) => dispatch({ type: APP_SET_HEADER_STATE, payload: headerState });
};

export const setGender = (gender, placement = GENDER_PLACEMENT.DEFAULT, component) => {
  return {
    type: SET_GENDER,
    payload: { gender, placement, component },
  };
};
export const setEmail = (email) => {
  return {
    type: 'SET_EMAIL',
    payload: email,
  };
};
export const setTypeaheadUtag = (data) => {
  return {
    type: SET_TYPEAHEAD_UTAG,
    payload: data,
  };
};
