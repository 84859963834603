/* eslint-disable consistent-return */
import Amplify, { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import {
  clearGuestTokens,
  getGuestTokens,
  associateFingerprint,
  getFingerprint,
} from '@nmg/auth';
import axios from 'axios';
import { localStorage, window } from 'window-or-global';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { saveToLocalStorage } from 'client-utils/utilities-localstorage';
import {
  sortAccounts,
  setStoreCookie,
  getStoreJson,
  setUcaProfileCookie,
  logoutUcaProfileCookie,
  removeCognitoCookies,
  knownCustomerCookie,
  logOutknownCustomerCookie,
} from '../common/components/YourNeimans/components/utilities';
import {
  amplifyConfig, hostList, hostListHC, amplifyConfigHC, fastlyHostListNM,
} from './amplifyConfig';
import getLinkDomain from './getlinkDomain';

// Delete refresh when atg gone
const superRefreshSession = CognitoUser.prototype.refreshSession;
CognitoUser.prototype.refreshSession = function refreshSession(
  refreshToken,
  callback,
  clientMetadata
) {
  const self = this;
  const cb = () => {
    const { DISABLE_ATG_LOGIN = false } = window?.YourNeimansConfig || {};
    const callAtgSession = window?.location?.hostname?.toLowerCase()?.includes('neimanmarcus');

    if (callAtgSession) {
      // eslint-disable-next-line
      obtainAtgSession(self, false, true, DISABLE_ATG_LOGIN);
    }
    return callback(null, self.signInUserSession);
  };
  superRefreshSession.call(self, refreshToken, cb, clientMetadata);
};

Hub.listen('auth', ({ payload: { event } }) => {
  if (event === 'signIn') {
    Auth.currentAuthenticatedUser({})
      .then((user) => {
        user.getCachedDeviceKeyAndPassword();
        if (user.deviceKey) {
          saveToLocalStorage('device_key', user.deviceKey);
        }
      })
      .catch((err) => err);
  } else if (event === 'signOut') {
    sessionStorage.removeItem('loyaltyData');
    localStorage.removeItem('_kmsi');
    logoutUcaProfileCookie();
    removeCognitoCookies(); // TODO: Delete this function when ATG will be removed
    logOutknownCustomerCookie();
  }
});

export const getJwtToken = (user) => user?.getSession((err, session) => (
  err ? new Error(err) : session.getIdToken().getJwtToken())
  );
export const getProfileId = (user) => user?.getSession((err, session) => (
  err ? new Error(err) : session.getIdToken().payload.preferred_username)
  );
export const getUcaId = (user) => user?.getSession((err, session) => (
  err ? new Error(err) : session.getIdToken().payload.sub)
  );
export const getLambdaHost = () => window.sessionStorage.getItem('lambdaHost');
export const getFastlyHost = () => {
  const fastlyHost = window.sessionStorage.getItem('fastlyUCAHost');

  return fastlyHost || window.sessionStorage.getItem('lambdaHost');
};

const sendErrorLog = (e, origin, user) => {
  Auth.signOut();
  const body = {
    data: {
      user,
      group: origin,
      errorStack: JSON.stringify(e.stack),
      errorMessage: JSON.stringify(e.message),
    },
    level: 'error',
  };

  axios.post(`${getLambdaHost()}/uca-logger/v1/log`, body);
};

const getLoyaltyData = (user, disableATGToggle = false) => {
  try {
    const headers = {
      Authorization: `Bearer ${getJwtToken(user)}`,
      'Content-Type': 'application/json',
    };
    const loyaltyData = window?.sessionStorage.getItem('loyaltyData');
    if (!loyaltyData && user?.attributes) {
      const profileId = disableATGToggle ? getUcaId(user) : getProfileId(user); // profile id related atg
      const customerDataAttributesUrl = disableATGToggle
        ? `${getFastlyHost()}/customer-data/v1/profiles/${profileId}/attributes`
        : `${getFastlyHost()}/customer-data/v1/${profileId}/attributes`;

      return axios
        .get(customerDataAttributesUrl, { headers })
        .then(({ data }) => {
          window.sessionStorage.setItem('loyaltyData', JSON.stringify(data));
          if (data.inCircleLevel) {
            headers['X-NMG-INCIRCLE-LEVEL'] = Array.isArray(data.inCircleLevel)
              ? data.inCircleLevel.join(',')
              : data.inCircleLevel;
          }
          return Promise.resolve(headers);
        })
        .catch(({ message }) => {
          window.sessionStorage.setItem('loyaltyData', message);
          return Promise.resolve(headers);
        });
    }
    return Promise.resolve(headers);
  } catch (e) {
    sendErrorLog(e, 'getLoyaltyData', user?.username);
    return Promise.reject(e);
  }
};

const migrateFavorites = (user) => {
  const { Sub } = getGuestTokens(['Sub']);
  const guestId = Sub;
  const profileId = getProfileId(user);
  const headers = {
    Authorization: `Bearer ${getJwtToken(user)}`,
    'Content-Type': 'application/json',
  };
  if (profileId && guestId) {
    axios
      .post(`/dt/api/mergeFavorites/${profileId}/${guestId}`, {}, { headers })
      .catch((e) => e);
  }
};

export const associateIds = (user) => {
  const { Sub } = getGuestTokens(['Sub']);
  if (Sub) {
    const ucaId = getUcaId(user);
    const headers = {
      Authorization: `Bearer ${getJwtToken(user)}`,
      'Content-Type': 'application/json',
    };
    const fastlyHost = getFastlyHost();
    axios
      .post(
        `${fastlyHost}/extended-profile/v1/profiles/${ucaId}/guests/${Sub}/associate`,
        {},
        { headers }
      )
      .then(() => {
        document.cookie = 'NMGAuthFinalTrigger=true';
      });
  }
};

export const getProfileStore = (
  profileId,
  jwtToken,
  ucaId,
  disableATGToggle
) => {
  const headers = {
    Authorization: `Bearer ${jwtToken}`,
  };

  const requestURL = disableATGToggle
    ? `${getFastlyHost()}/uca-favorites/v1/profiles/${ucaId}/stores`
    : `${getFastlyHost()}/uca-favorites/v1/accounts/${profileId}/stores`;

  return axios.get(requestURL, { headers });
};

const updateStore = (
  profileId,
  jwt,
  ucaId,
  disableATGToggle
) => getProfileStore(profileId, jwt, ucaId, disableATGToggle).then(
  ({ data: { stores } }) => {
    const [store] = stores;
    const id = store?.id;
    if (id) {
      return getStoreJson(id).then(({ data }) => {
        if (data?.id) {
          setStoreCookie(data);
          window.YourNeimans.setData({
            storeChanged: true,
          });
        } else {
          ['dt_favorite_store', 'dt_selected_store'].forEach((cookie) => {
            document.cookie = `${cookie}=; expires=01 Jan 1970 00:00:00 GMT; path=/`;
          });
        }
      });
    }
  }
);

const handlePostLogin = (user, isRefresh, disableATGToggle) => {
  const jwt = getJwtToken(user);
  const profileId = getProfileId(user);
  const ucaId = getUcaId(user);
  if (!isRefresh) {
    associateIds(user);
  }
  clearGuestTokens();
  const fingerprint = getFingerprint();
  const loginPromises = [
    updateStore(profileId, jwt, ucaId, disableATGToggle),
    fingerprint
      ? associateFingerprint(false, fingerprint, ucaId, {
        Authorization: `Bearer ${jwt}`,
      })
      : Promise.resolve(),
  ];

  return Promise.allSettled(loginPromises);
};

export const obtainAtgSession = (
  user,
  justRegistered = false,
  isRefresh = false,
  disableATGToggle = false,
) => {
  try {
    if (getJwtToken(user)) {
      const favoritesAdded = Object.keys(localStorage).filter((key) => key.includes('.favoritesAdded'));
      if (favoritesAdded.length) {
        migrateFavorites(user);
        favoritesAdded.forEach((key) => localStorage.removeItem(key));
      }
      return getLoyaltyData(user, disableATGToggle)
        .then((headers) => (disableATGToggle ? Promise.resolve() : axios.post(
          `${getLinkDomain()}/migration/profile/login`,
          { justRegistered },
          { headers }
        )))
        .then(() => handlePostLogin(user, isRefresh, disableATGToggle))
        .catch((e) => sendErrorLog(e, 'obtainAtgSession', user?.username));
    }
    throw new Error('Token undefined');
  } catch (e) {
    sendErrorLog(e, 'obtainAtgSession', user?.username);
    // eslint-disable-next-line
    return Promise.reject({});
  }
};

export const configureAmplify = (env, brand = 'NM', USE_CONFIDENTIAL_CLIENT = false) => {
  if (brand === 'HC') {
    const envWithFallbackHC = env || 'hc-prod';
    Amplify.configure({ Auth: amplifyConfigHC[envWithFallbackHC], ssr: true });
    const lambdaHost = hostListHC[envWithFallbackHC];
    // eslint-disable-next-line
    window?.sessionStorage.setItem("lambdaHost", lambdaHost);
  } else {
    const envWithFallback = env || 'prod';

    Amplify.configure({
      Auth: amplifyConfig(USE_CONFIDENTIAL_CLIENT)[envWithFallback], ssr: true,
    });
    const lambdaHost = hostList[envWithFallback];
    const fastlyHost = fastlyHostListNM[envWithFallback];

    // eslint-disable-next-line
    window?.sessionStorage.setItem("lambdaHost", lambdaHost);

    // eslint-disable-next-line
    window?.sessionStorage.setItem("fastlyUCAHost", fastlyHost);
  }
};

export const amplifyLogin = (
  email,
  password,
  errorMethod = () => {},
  justRegistered,
  successCallback = () => {},
  dtLoginFlowToggle = false,
  ucaProfileCookieToggle = false,
  pzpToggle = false,
  disableATGToggle = false,
  ucaMfaToggle = false,
  goToMfa = () => {},
  EMPLOYEE_STATUS_V2,
  DISCOUNT_ELIGIBILITY_V1,
  shouldShowCaptcha = false,
  token = '',
) => {
  document.cookie = 'NMGAuthFinalTrigger=false';
  const lowerCaseEmail = email.trim().toLowerCase();
  const successCbandSetCookie = (user) => {
    knownCustomerCookie(user, pzpToggle);
    if (ucaProfileCookieToggle) {
      setUcaProfileCookie(
        user,
        successCallback,
        EMPLOYEE_STATUS_V2,
        DISCOUNT_ELIGIBILITY_V1,
      );
    } else successCallback(user);
  };


  const validationData = [];
  if (shouldShowCaptcha) validationData.push({ Name: 'captcha', Value: token });

  if (ucaMfaToggle) {
    Auth.signIn({ username: lowerCaseEmail, password }, validationData)
      .then((user) => {
        if (user.challengeName === 'SMS_MFA') {
          // eslint-disable-next-line
          user.extra = { email: lowerCaseEmail };
          goToMfa(user);
          if (successCallback) {
            successCallback(user);
          }
          return;
        }

        obtainAtgSession(user, justRegistered, false, disableATGToggle)
          .then(() => successCbandSetCookie(user))
          .catch(({ code }) => errorMethod(code));
      })
      .catch(({ code, message }) => {
        if (code === 'UserNotConfirmedException' && message === 'User is not confirmed.') {
          window.location.href = `/my/verify-email?id=${btoa(lowerCaseEmail)}`;

          return;
        }

        if (message && message.split('|').length === 3) {
          const messageToDisplay = message.split('|')[1];
          errorMethod(`AccountLockedError|${messageToDisplay}`);
          return;
        }
        // add captcha error message
        errorMethod(code);
      });

    return;
  }

  if (dtLoginFlowToggle) {
    Auth.signIn({ username: lowerCaseEmail, password }, validationData)
      .then(
        (user) => obtainAtgSession(
          user,
          justRegistered,
          false,
          disableATGToggle
        )
          .then(
            () => successCbandSetCookie(user)
          )
      )
      .catch(({ code, message }) => {
        if (message && message.split('|').length === 3) {
          const messageToDisplay = message.split('|')[1];
          errorMethod(`AccountLockedError|${messageToDisplay}`);

          return;
        }

        // add captcha error message

        errorMethod(code);
      });

    return;
  }
  // below method to take in additional data for recaptcha
  Auth.signIn({ username: lowerCaseEmail }, validationData)
    .then((user) => {
      if (user.challengeName === 'CUSTOM_CHALLENGE') {
        Auth.sendCustomChallengeAnswer(user, password)
          .then(
            (user2) => obtainAtgSession(
              user2,
              justRegistered,
              false,
              disableATGToggle
            ).then(() => successCbandSetCookie(user2))
          )
          .catch(({ code }) => {
            errorMethod(code);
          });
      }
    })
    .catch(({ code, message }) => {
      if (message.split('|').length === 3) {
        const messageToDisplay = message.split('|')[1];
        errorMethod(`AccountLockedError|${messageToDisplay}`);
      } else if (
        code === 'UserNotFoundException'
        || code === 'NotAuthorizedException'
      ) {
        Auth.signIn({ username: lowerCaseEmail, password }, validationData)
          .then(
            (user) => obtainAtgSession(
              user,
              justRegistered,
              false,
              disableATGToggle
            ).then(() => successCbandSetCookie(user))
          )
          .catch(() => {
            errorMethod(code);
          });
      } else errorMethod(code);
    });
};

export const amplifySignUp = (
  email,
  password,
  firstName,
  lastName,
  phone,
  signupErrCallback = () => {},
  loginCallbackError = () => {},
  successCallback = () => {},
  dtLoginFlowToggle = false,
  ucaProfileCookieToggle = false,
  pzpToggle = false,
  disableATGToggle = false,
  ucaMfa = false,
  goToMfa = () => {},
  EMPLOYEE_STATUS_V2,
  DISCOUNT_ELIGIBILITY_V1,
  token,
  shouldShowCaptcha = false,
  phoneCheckMandatory = false,

) => {
  const attributes = {
    given_name: firstName,
    family_name: lastName,
    'custom:temp': password,
    'custom:brand': 'NM',
  };

  const validationData = [];

  if (phone) attributes.phone_number = `+1${phone}`;

  if (shouldShowCaptcha) validationData.push({ Name: 'captcha', Value: token });
  if (phoneCheckMandatory) validationData.push({ Name: 'phoneValidation', Value: phoneCheckMandatory?.toString() });

  Auth.signUp({
    username: email.toLowerCase(),
    password,
    attributes,
    validationData,
  })
    .then((data) => {
      if (data?.codeDeliveryDetails?.AttributeName === 'email' && !data?.userConfirmed) {
        window.location.href = `/my/verify-email?id=${btoa(email.toLowerCase())}`;
        return;
      }
      const justRegistered = true;
      amplifyLogin(
        email,
        password,
        loginCallbackError,
        justRegistered,
        successCallback,
        dtLoginFlowToggle,
        ucaProfileCookieToggle,
        pzpToggle,
        disableATGToggle,
        ucaMfa,
        goToMfa,
        EMPLOYEE_STATUS_V2,
        DISCOUNT_ELIGIBILITY_V1,
        false,
        ''
      );
    })
    .catch(({ message, name }) => {
      signupErrCallback(message, name);
    });
};

export const handleProtectedClick = (redirectLink) => {
  Auth.currentSession()
    .then(() => {
      window.location.href = redirectLink;
    })
    .catch(() => {
      window.location.replace(redirectLink);
    });
};

// eslint-disable-next-line no-useless-escape
export const validateEmail = (email) => {
  if (email.indexOf('@') === -1 || email.indexOf('.') === -1) {
    return false;
  }
  if (email.slice(0, email.indexOf('@')).length < 2) {
    return false;
  }
  const regex = /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,10})+$/;
  return email && regex.test(email);
};

export const validateName = (name) => {
  const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (name.trim() === '') {
    return false;
  }
  return !expression.test(name.trim());
};

export const entryValidation = (email, password) => {
  if (email && password && validateEmail(email.trim())) {
    return true;
  }
  return false;
};

export const logout = (
  disableATGToggle = false,
  redirectToVerify = false,
  userAttributes = {}
) => {
  const { email } = userAttributes;
  const customer = email ? `?customer=${email}` : '';
  Auth.signOut().then(() => {
    const itemsToRemove = ['loyaltyData', 'incircleData', 'promoCards', 'loyalty_info'];
    itemsToRemove.forEach((item) => window.sessionStorage.removeItem(item));
    localStorage.removeItem('incircleUserLevel');
    removeCognitoCookies();

    if (!disableATGToggle) {
      if (redirectToVerify) {
        axios.get('/dt/api/logoutAtg');
        window.location.href = `/login/verify${customer}`;
      } else window.location.href = '/profile.service?action=logout';
    } else {
      window.location.href = !redirectToVerify ? '/' : `/login/verify${customer}`;
    }
  });
};

const postLoginError = (errorMessage = '', context) => {
  context.setState({
    errorMessage,
    displayError: true,
    loading: false,
  });
};

export const loginErrorCallBack = (error = '', context, email, LOCK_AUTHSTATUS_API) => {
  localStorage.removeItem('_kmsi');
  let errorMessage = 'Something went wrong, Please try again';

  if (error === 'NotAuthorizedException') {
    if (LOCK_AUTHSTATUS_API) {
      const lambdaHost = getLambdaHost();
      axios.post(`${lambdaHost}/extended-profile/v1/authentication-status`, { email }).then(() => {}).catch((e) => {
        errorMessage = e?.response?.data?.message || errorMessage;
        postLoginError(errorMessage, context);
      });
    } else {
      errorMessage = 'The supplied email address or password is incorrect.';
      postLoginError(errorMessage, context);
    }
  } else if (error === 'UserNotFoundException') {
    errorMessage = 'The supplied email address or password is incorrect.';
    postLoginError(errorMessage, context);
  } else if (error === 'PasswordResetRequiredException') {
    errorMessage = "We've increased our password security. Please reset your password by clicking “Forgot Password?” below.";
    postLoginError(errorMessage, context);
  } else if (error === 'ResourceNotFoundException') {
    errorMessage = 'Please try again with different browser';
    postLoginError(errorMessage, context);
  } else if (error.startsWith('AccountLockedError')) {
    if (LOCK_AUTHSTATUS_API) {
      const lambdaHost = getLambdaHost();
      axios.post(`${lambdaHost}/extended-profile/v1/authentication-status`, { email }).then(() => {}).catch((e) => {
        errorMessage = e?.response?.data?.message || errorMessage;
        postLoginError(errorMessage, context);
      });
    } else {
      // eslint-disable-next-line prefer-destructuring
      // eslint-disable-next-line no-unused-vars
      const [_, displayError] = error.split('|');
      errorMessage = displayError || _;
      postLoginError(errorMessage, context);
    }
  } else {
    postLoginError(errorMessage, context);
  }
};

export const incircleModule = {
  getIncircleData: () => {
    const rawIncircleData = sessionStorage.getItem('incircleData');
    if (rawIncircleData === null) {
      return Auth.currentAuthenticatedUser({})
        .then((user) => {
          const profileId = getProfileId(user);
          const jwtToken = getJwtToken(user);
          const fastlyHost = getFastlyHost();
          const headers = {
            Authorization: `Bearer ${jwtToken}`,
          };
          return axios
            .get(`${fastlyHost}/customer-data/v1/${profileId}/incircle`, {
              headers,
            })
            .then((incircleResponse) => {
              const sortedIncircleData = sortAccounts(incircleResponse.data);
              sessionStorage.setItem(
                'incircleData',
                JSON.stringify(sortedIncircleData)
              );
              saveToLocalStorage(
                'incircleUserLevel',
                sortedIncircleData[0].level
              );
              return incircleResponse.data;
            });
        })
        .catch((e) => {
          if (
            e.response?.data.message.toLowerCase()
            === 'incircle data not found.'
          ) {
            sessionStorage.setItem('incircleData', '[]');
          }
          return e;
        });
    }
    const incircleData = JSON.parse(rawIncircleData);
    return Promise.resolve(incircleData);
  },
};

export const getAuthApiData = () => Auth.currentAuthenticatedUser({}).then(
  (user) => ({
    lambdaHost: getLambdaHost(),
    fastlyHost: getFastlyHost(),
    profileId: getProfileId(user), // TODO: remove when ATG is down.
    headers: {
      Authorization: `Bearer ${getJwtToken(user)}`,
    },
    ucaId: getUcaId(user),
    user,
  })
);

export const getEnv = () => {
  const envs = [
    ['localhost', 'dev-int'],
    ['stage', 'prep'],
    ['perf', 'prep'],
    ['devint', 'dev-int'],
    ['devint2', 'dev-int2'],
    ['neimanmarcus', 'prod'],
  ];

  const hostName = window.location.hostname.toLowerCase();
  const validEnv = envs.filter((arr) => hostName.includes(arr[0]));
  const env = validEnv?.[0]?.[1];
  return env;
};

const postFavoriteStore = (
  data,
  fastlyHost,
  profileId,
  headers,
  isGuest = true
) => {
  const userType = isGuest ? 'guests' : 'accounts';
  return axios.post(
    `${fastlyHost}/uca-favorites/v1/${userType}/${profileId}/stores`,
    data,
    { headers }
  );
};

export const sendSelectedStore = (store) => {
  const data = {
    channel: 'NMO',
    value: store.storeNumber,
  };

  getAuthApiData()
    .then((authInfo) => {
      postFavoriteStore(
        data,
        authInfo.fastlyHost,
        authInfo.profileId,
        authInfo.headers,
        false
      );
    })
    .catch(() => {
      const { AccessToken, Sub } = getGuestTokens(['AccessToken', 'Sub']);
      const lambdaHost = getLambdaHost();
      const headers = {
        Authorization: `Bearer ${AccessToken}`,
      };
      postFavoriteStore(data, lambdaHost, Sub, headers, true);
    });
};
